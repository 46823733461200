import Contact from "views/Contact"
import Layout from "components/Layout"
import React from "react"
import SEO from "components/seo"
import { useTranslation } from "react-i18next"
const ContactPage = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO
        title={t("CONTACT_TITLE")}
        description="Moderní, kvalitní a bezpečné přístroje za dostupnou cenu. Široká nabídka stylových vypínačů a zásuvek pro jakýkoliv interiér."
      />
      <Contact />
    </Layout>
  )
}

export default ContactPage
